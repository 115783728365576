
export default function CONTACT() {
    return <pre>
        Contact Us
        Last updated on 05-08-2024 22:15:06     
        You may contact us using the information below:
        Merchant Legal entity name: PRINCE GUPTA
        Registered Address: B 3/1, Imagine Residency, Jamshedpur, Jharkhand, PIN: 831012 Operational Address: B 3/1, Imagine Residency, Jamshedpur, Jharkhand, PIN: 831012 Telephone No: 8877102431
        E-Mail ID: upgrace.in@gmail.com
    </pre>
}